.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.bottom-label {
  position: absolute;
  bottom: 0;
  right: 0;
}

.App-header {
  background-color: #eee;
  position: absolute;
  top: 0;
  min-height: 100%;
  left: 0;
  overflow-x: hidden;
  width: 100%
}

.separator {
  width: 100%;
  height: 1px;
  background: #aaa;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
